import { computed } from "vue";
import { QualIssue } from '@/services/QualClasses';
import { useFormStores as useStores } from '@/rules/_useFormStores';

export const rules = {
  // check if enabled and complete
  enabledAndIncomplete: computed(() => {
    let { lead, validator } = useStores('floor', true);
    // check if interest is checked
    if (!lead.interestsArray.includes('Floor')) {
      return null;
    }
    // if checked, check if form is valid
    if (validator.$invalid) {
      return new QualIssue({
        view: 'formFloor',
        title: 'Belangrijke informatie ontbreekt',
        description:
          'Niet alle vereiste informatie is ingevuld. Daarom kunnen wij (nog) niet garanderen dat u in aanmerking komt voor vloer-/bodemisolatie.',
      });
    }
    return null;
  }),

  // must have (crawl) space beneath floor
  SpaceBeneathFloor: computed(() => {
    let { form } = useStores('floor');
    if (form.general.SpaceBeneathFloor == false) {
      return new QualIssue({
        view: 'formFloor',
        title: 'Geen ruimte onder de vloer',
        description:
          'Er dient een (kruip)ruimte onder de vloer aanwezig te zijn om te isoleren.',
      });
    }
    return null;
  }),

  // space under floor must be accessible
  CrawlSpaceAccessible: computed(() => {
    let { form } = useStores('floor');
    if (form.general.CrawlSpaceAccessible == false) {
      return new QualIssue({
        view: 'formFloor',
        title: '(Kruip)ruimte niet bereikbaar',
        description:
          'De ruimte onder de vloer dient bereikbaar te zijn via een kruipluik of mangat.',
      });
    }
    return null;
  }),

  // crawl space height must be at least 25cm
  CrawlSpaceHeightCm: computed(() => {
    let { form } = useStores('floor');
    if (form.details.CrawlSpaceHeightCm !== null && form.details.CrawlSpaceHeightCm < 25) {
      return new QualIssue({
        view: 'formFloorDetails',
        title: 'Niet genoeg ruimte onder de vloer',
        description:
          'De hoogte van de (kruip)ruimte onder de vloer moet minimaal 25cm zijn om effectief te isoleren.',
      });
    }
    return null;
  }),
  
};
