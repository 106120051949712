import { computed } from "vue";
import { QualIssue } from '@/services/QualClasses';
import { useFormStores as useStores } from '@/rules/_useFormStores';

export const rules = {
  // check if enabled and complete
  enabledAndIncomplete: computed(() => {
    let { lead, validator } = useStores('heatpump', true);
    // check if interest is checked
    if (!lead.interestsArray.includes('Heatpump')) {
      return null;
    }
    // if checked, check if form is valid
    if (validator.$invalid) {
      return new QualIssue({
        view: 'formHeatpump',
        title: 'Belangrijke informatie ontbreekt',
        description:
          'Niet alle vereiste informatie is ingevuld. Daarom kunnen wij (nog) niet bepalen of u in aanmerking komt voor een warmtepomp.',
      });
    }
    return null;
  }),

  // monuments are disqualified
  HeatingSystem: computed(() => {
    let { form } = useStores('heatpump');
    if (!form.HeatingSystem) {
      return new QualIssue({
        view: 'formHeatpump',
        title: 'Geef aan welk warmteafgiftesysteem uw woning heeft',
        description:
          'Het warmteafgiftesysteem is een belangrijke factor bij advies over warmtepompen.',
      });
    }
    return null;
  })

};
