import { computed } from "vue";
import { QualIssue } from '@/services/QualClasses';
import { useFormStores as useStores } from '@/rules/_useFormStores';

export const rules = {
  // check if enabled and complete
  enabledAndIncomplete: computed(() => {
    let { lead, validator } = useStores('cavity_wall', true);
    // check if interest is checked
    if (!lead.interestsArray.includes('Cavity Wall')) {
      return null;
    }
    // if checked, check if form is valid
    if (validator.$invalid) {
      return new QualIssue({
        view: 'formCavityWall',
        title: 'Belangrijke informatie ontbreekt',
        description:
          'Niet alle vereiste informatie is ingevuld. Daarom kunnen wij (nog) niet garanderen dat u in aanmerking komt voor spouwmuurisolatie.',
      });
    }
    return null;
  }),

  // spouwmuur aanwezig
  CavityWallPresent: computed(() => {
    let { form } = useStores('cavity_wall');
    if (form.general.CavityWallPresent == false) {
      return new QualIssue({
        view: 'formCavityWall',
        title: 'Geen spouwmuur aanwezig',
        description:
          'Indien er geen spouwmuur aanwezig is, kunnen wij geen spouwmuurisolatie uitvoeren.',
      });
    }
    return null;
  }),

  // materiaal buitenmuur
  FacadeMaterial: computed(() => {
    let { form } = useStores('cavity_wall');
    if (form.general.FacadeMaterial && form.general.FacadeMaterial !== 'Steen_spouw') {
      return new QualIssue({
        view: 'formCavityWall',
        title: 'Buitenmuur is ongeschikt',
        description: `Bij de aangegeven soort buitenmuur (${form.general.FacadeMaterial}) kunnen wij geen spouwmuurisolatie uitvoeren.`,
      });
    }
    return null;
  }),

  // geverfde muren
  WallsPainted: computed(() => {
    let { form } = useStores('cavity_wall');
    if (form.paint.WallsPainted) {
      return new QualIssue({
        view: 'formCavityWallPaint',
        type: 'warning',
        title: 'Let op: risico op schade!',
        description:
          'Bij geverfde muren bestaat er een risico op vorstschade omdat er mogelijk vocht in de muur komt. Wij raden u af te isoleren.',
      });
    }
    return null;
  }),

  // muur opnieuw verven
  AcceptWallRepaint: computed(() => {
    let { form } = useStores('cavity_wall');
    if (form.paint.WallsPainted && form.paint.AcceptWallRepaint == false) {
      return new QualIssue({
        view: 'formCavityWallPaint',
        punishEarly: false,
        title: 'Spouwmuurisolatie niet mogelijk',
        description:
          'Wij begrijpen dat u het risico niet acceptabel vindt. Ga door om te kijken of uw situatie wel geschikt is voor andere maatregelen.',
      });
    } else if (form.paint.WallsPainted && form.paint.AcceptWallRepaint == true) {
      return new QualIssue({
        view: 'formCavityWallPaint',
        type: 'warning',
        title: 'Let op: isoleren op eigen risico!',
        description:
          'U verklaart het risico te accepteren en aanvaard uitvoering zonder garantie. U begrijpt dat u bij eventuele schade aan de gevel zelf aansprakelijk bent.',
      });
    }
    return null;
  }),

  // stucwerk
  AcceptStuccoRepaint: computed(() => {
    let { form } = useStores('cavity_wall');
    if (form.stucco.WallsPlastered && form.stucco.AcceptStuccoRepaint == false) {
      return new QualIssue({
        view: 'formCavityWallStucco',
        title: 'Stucwerk opnieuw verven & reliëf aanbrengen',
        description:
          'Bij isolatie zouden de boorgaten moeten worden hersteld en het reliëf opnieuw moeten worden aangebracht. U geeft aan dit niet te willen. In dat geval kunnen wij niet isoleren.',
      });
    }
    return null;
  }),
  
};
