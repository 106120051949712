import { useInterfaceStore } from '@/store/UiStore';
import { useLeadStore } from '@/store/LeadStore';
import { useGlobalVuelidate } from '@/store/LeadValidator';

import axios from 'axios';
import constants from '@/constants';
// import { touchAllWithValue } from '@/store/LeadValidator';

export var api = axios.create({
  baseURL: constants.baseURL,
});

var startSaving = function() {
  let uiStore = useInterfaceStore();
  uiStore.savingError = false;
  uiStore.saving = true;
}
var endSaving = function(withError = false) {
  let uiStore = useInterfaceStore();
  uiStore.saving = false;
  if (withError) {
    uiStore.savingError = true;
  }
}

export const LeadDataService = {
  fetchRemoteData(uuid = null) {
    return new Promise((resolve, reject) => {
      let lead = useLeadStore();
      let uiStore = useInterfaceStore();
      // new api call
      api
        .get('/rest/lead/' + uuid)
        .then(function (response) {
          if (response.data && response.data.data && response.data.data.state && response.data.status && response.data.status == 'success') {
            // lead found
            lead.loadRemoteData(response.data.data.state);
            if (uiStore.uuidType == 'session') {
              uiStore.fetchedFromSession = true;
            } else {
              uiStore.fetchedFromSf = true;
            }
            // touchAllWithValue();
            resolve();
          } else {
            // lead not found
            uiStore.sfQueried = true;
            reject();
          }
        })
        .catch(function (error) {
          // connection error
          console.error(error);
          reject();
        });
    });
  },

  fetchDummyData(uuid = null) {
    return new Promise((resolve) => {
      let uiStore = useInterfaceStore();
      let leadStore = useLeadStore();

      uiStore.setUuid(uuid);
      // simulate salesforce loading time
      window.setTimeout(() => {
        leadStore.loadDummyData();
        uiStore.fetchedFromSf = true;
        endSaving();
        resolve();
      }, 200);
    });
  },

  pushRequired(v$ = null) {
    if (!v$) {
      v$ = useGlobalVuelidate();
    }
    if (v$.value.$anyDirty) {
      return true;
    }
    return false;
  },

  pushRemoteData(uuid = null, push_sf = false) {
    return new Promise((resolve, reject) => {
      startSaving();
      let lead = useLeadStore();
      let uiStore = useInterfaceStore();
      let postUrl = uuid ? '/rest/lead/' + uuid : '/rest/lead/';
      // check if push is required
      if (!this.pushRequired() && !push_sf) {
        endSaving();
        resolve();
        return;
      }
      // new api call
      console.log(push_sf, uiStore.isPortalUser);
      api
        .post(postUrl, {
          app_state: lead.$fields,
          push_sf: push_sf,
          push_portal: push_sf && uiStore.isPortalUser ? true : false,
          qualified_interests: lead.QualifiedInterests
        })
        .then(function (response) {
          if (response.data && response.data.status && response.data.status == 'success') {
            // lead stored
            let remoteState = response.data.data;
            uiStore.setUuid(remoteState.uuid, true);
            endSaving();
            resolve();
          } else {
            console.log(response);
            endSaving(true);
            reject();
          }
        })
        .catch(function (error) {
          // connection error
          console.error(error);
          endSaving(true);
          reject();
        });
    });
  },

  submitCallRequest(uuid) {
    return new Promise((resolve, reject) => {
      let lead = useLeadStore();
      // new api call
      api
        .post('/rpc/lead/call_request/' + uuid)
        .then(function (response) {
          if (response.data && response.data.status == 'success') {
            // lead found
            lead.meta.CallBackRequested = true;
            // touchAllWithValue();
            resolve();
          } else {
            // lead not found
            reject();
          }
        })
        .catch(function (error) {
          // connection error
          console.error(error);
          reject();
        });
    });
  },

  retractCallRequest(uuid) {
    return new Promise((resolve, reject) => {
      let lead = useLeadStore();
      // new api call
      api
        .post('/rpc/lead/call_request_retract/' + uuid)
        .then(function (response) {
          if (response.data && response.data.status == 'success') {
            // lead found
            lead.meta.CallBackRequested = false;
            // touchAllWithValue();
            resolve();
          } else {
            // lead not found
            reject();
          }
        })
        .catch(function (error) {
          // connection error
          console.error(error);
          reject();
        });
    });
  }
};
